import React from 'react'
import ExplorePage from 'src/components/Page/Author/Explore'



const ExplorePageContainer = ({ location }) => {
    return <ExplorePage location={location} />
}

export default ExplorePageContainer

